jQuery(document).ready(function($){

    $('.btn-inquiry-modal[data-open="menu-contact-modal"]').click(function() {
        const $parent = $(this).closest('[data-product-parent]');
        const $container = $(this).closest('[data-product-section]');
        const title = $('.breadcrumb_last').text();
        const finish = $container.find('[data-product-finish]').text();
        const description = $parent.find('[data-product-properties]').text();

        const $form = $('#menu-contact-modal');

        if (!$form.length) {
            return;
        }

        const $your_product = $form.find('[data-name="your-product"] > input');
        const $your_finish = $form.find('[data-name="your-finish"] > input');
        const $your_properties = $form.find('[data-name="your-properties"] > textarea');

        if (!$your_product.length || !$your_finish.length || !$your_properties.length) {
            return;
        }

        $your_product.val(title);
        $your_finish.val(finish);
        $your_properties.val(description);

        $your_product.attr('readonly', true);
        $your_finish.attr('readonly', true);
    });

});
